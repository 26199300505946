/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient } from '@tanstack/react-query';
import { getGlobalLogError } from '@mf/common/components/Logs/LogManager.hooks';

export const defaultReactQuerySettings = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 90 * 10000, // 15 min => TODO verify staleTime
      onError: (error: any) => {
        const logError = getGlobalLogError();
        logError(error, { source: `React Query Query Error` });
      },
    },
  },
};

export const queryClient = new QueryClient(defaultReactQuerySettings);

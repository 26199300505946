'use client';

import { ButtonSize, FsButton, FsLink } from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useEffect, useState } from 'react';
import * as S from './Cookies.styles';

export const Cookies = () => {
  const { isMobile } = useLayout();
  const [client, setClient] = useState(false);
  const [accept, setAccept] = useState(false);

  useEffect(() => {
    setClient(true);
    const acceptCookies = localStorage.getItem('acceptCookies');
    if (acceptCookies) {
      setAccept(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('acceptCookies', 'true');
    setAccept(true);
  };

  if (!client) {
    return <></>;
  }

  return accept ? (
    <></>
  ) : (
    <S.Container className={accept ? 'hide' : 'show'}>
      <S.Content>
        <S.Paragraph inverse>
          Esse site utiliza cookies e dados pessoais de acordo com nossas{' '}
          {client && (
            <FsLink inverse href="/politicas-de-cookies/" target="_self">
              Políticas de cookies
            </FsLink>
          )}
          ,{!isMobile && <br></br>} ao continuar navegando você declara estar
          ciente dessas condições.
        </S.Paragraph>
        <FsButton
          type="button"
          inverse
          size={ButtonSize.LG}
          onClick={() => handleAccept()}
        >
          OK, entendi
        </FsButton>
      </S.Content>
    </S.Container>
  );
};

import { media } from '@mf/common/utils/styles/index';
import { FsParagraph } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: var(--z-index-fixed);

  p,
  a,
  button {
    z-index: var(--z-index-fixed);
  }
`;

export const Content = styled.div`
  transition: 0.5s;
  position: fixed;
  display: flex;
  padding: var(--spacing-5x);
  align-items: center;
  gap: var(--spacing-15x);
  left: 0;
  right: 0;
  bottom: 0;

  background: var(--elements-bg-color-03);

  ${media.lessThan('mobile')} {
    width: unset;
    display: flex;
    padding: var(--spacing-3x);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-4x);
  }
`;

export const Paragraph = styled(FsParagraph)`
  flex: 1 0 0;

  ${media.lessThan('mobile')} {
    a {
      height: unset;
    }
  }
`;

'use client';
import { useEffect } from 'react';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';

export function useGlobalErrorHandler() {
  const { logError } = useLogManager();

  useEffect(() => {
    const handleWindowError = (event: ErrorEvent) => {
      logError(event.error || 'Erro desconhecido no window.onerror', {
        source: 'window.onerror',
      });
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      logError(event.reason || 'Unhandled promise rejection', {
        source: 'Unhandled promise rejection',
      });
    };

    window.addEventListener('error', handleWindowError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleWindowError);
      window.removeEventListener(
        'unhandledrejection',
        handleUnhandledRejection,
      );
    };
  }, [logError]);
}

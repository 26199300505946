'use client';
import { ReactNode } from 'react';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';
import { ErrorBoundary } from './ErrorBoundary';

interface ErrorBoundaryWrapperProps {
  children: ReactNode;
}

export function ErrorBoundaryWrapper({ children }: ErrorBoundaryWrapperProps) {
  const { logError } = useLogManager();
  return <ErrorBoundary logError={logError}>{children}</ErrorBoundary>;
}
